import React from 'react';
import Citation from '../../../components/Citation';
import TextWithPic from '../../../components/TextWithPic';

const OilPlatform = () => {
    return (
        <div id='oil-platform' className='padding-bottom-150 padding-top-150'>
            <div className='max-width-content'>
                <TextWithPic 
                    className="margin-bottom-150" 
                    href="/images/oil-platform.png" 
                    imgDescription="Gas flares from an offshore oil platform in the Guneshli field near Azerbaijan." 
                    alt="oil platform" textAlign='right'
                >
                    <span>
                        The pollution from Azerbaijan's fossil fuel industry has <b className='colored'>led to serious health issues</b> for its population. In Sumqayit, a city plagued by industrial pollution, residents suffer from <b>high rates of cancer and respiratory diseases</b>. In Baku, the capital, oil extraction and refining have led to chronic illnesses like asthma and cardiovascular disease. Despite these issues, the government continues to obstruct efforts to clean up the environment, refusing to release accurate data and interfering with international projects aimed at reducing pollution.
                        <br/>
                        <br/>
                        Given the country's deep ties to fossil fuels and its poor environmental record, allowing Azerbaijan to host COP29—the world's most important climate summit—seems contradictory. The <b>international community must prevent fossil fuel interests</b> from undermining critical climate agreements. As UN Climate Chief Simon Stiell warned, we only have two years to make the necessary shifts to a green economy and avoid climate catastrophe.
                    </span>
                </TextWithPic>
                <Citation 
                    className="env-destr-citation max-width-content"
                    author='Crude Accountability'
                    authorDescription='Azerbaijans Green Energy Development Serves the Hydrocarbon Industry' 
                >
                    We find it problematic to call the Azerbaijani government's green initiatives a transition into a sustainable future: <span>Azerbaijan plans to invest</span> in renewable energy sources <span>to power its natural gas production facilities</span> and to export the displaced gas. Investors who finance the projects, intentionally or not, partake in this implementation.
                </Citation>
            </div>
        </div>
    );
};

export default OilPlatform;
