import React from 'react';
import TextWithPic from '../../../components/TextWithPic';
import styles from './EnvDestruction.module.css';

const EnvDestruction = () => {
    return (
        <div id='env-destr' className={styles.envDestr}>
            <div className='max-width-content'>
                <div className='text-header padding-top-150'>
                    Environmental Destruction
                </div>
                <TextWithPic 
                    className="margin-bottom-150 padding-bottom-150" 
                    href="/images/stats-env-destr.png" 
                    imgDescription="of Azerbaijan total export is fossil fuels." 
                    alt="square stub" textAlign='left'
                >
                    <span>
                        Azerbaijan's economy <b className="colored">is heavily dependent on fossil fuels</b>, with oil and gas making up 92% of its exports and 98% of its internal energy mix. Despite claiming to shift toward green energy, the country has plans to expand fossil fuel production and double natural gas exports to the EU. The state energy company, SOCAR, has a poor environmental record and gets a <b className="colored">0.9/100</b> from the World Benchmark Alliance, scoring in the bottom 10 oil companies in the world..
                        <br/>
                        <br/>
                        Even after signing the Paris Climate Accord in 2016, Azerbaijan has <b>failed to meet its climate commitments</b>. 
                    </span>
                </TextWithPic>
            </div>
        </div>
    );
};

export default EnvDestruction;
