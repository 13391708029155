import React from 'react';
import styles from './HowHelp.module.css';
import ArrowButton from '../../../components/ArrowButton';

const HowHelp = () => {
    return (
        <div className={'max-width-content ' + styles.howHelp}>
            <div className={styles.textHeader}>
                How to support?
            </div>
            <div className={styles.linkContainer}>
                <div className={styles.linkItem}>
                    <div className={styles.linkItemIcon}>
                        <ArrowButton type='right' size='3.75rem' />
                    </div>
                    <div className={styles.linkItemText}>
                        <a href="https://www.change.org/p/cop29-stop-greenwashing-azerbaijan-genocidal-petro-dictatorship-98d5c82a-f0bd-4e7b-80da-12fc12807f58" target="_blank" className={styles.helpLink}>Sign this Petition</a> from LIBERTAS Collective to stop COP29 in Baku!
                    </div>
                </div>
                <div className={styles.linkItem}>
                    <div className={styles.linkItemIcon}>
                        <ArrowButton type='right' size='3.75rem' />
                    </div>
                    <div className={styles.linkItemText}>
                        <a href="https://www.amnesty.org/en/documents/eur55/8097/2024/en/" target="_blank" className={styles.helpLink}>Send this Letter</a> from Amnesty International urging the release of political prisoner Tofig Yagublu
                    </div>
                </div>
                <div className={styles.linkItem}>
                    <div className={styles.linkItemIcon}>
                        <ArrowButton type='right' size='3.75rem' />
                    </div>
                    <div className={styles.linkItemText}>
                        <a href="https://www.amnesty.org/en/documents/eur55/8048/2024/en/" target="_blank" className={styles.helpLink}>Send this Letter</a> from Amnesty International urging the release of corruption whistleblower Dr. Gubad Ibadoghlu
                    </div>
                </div>
                <div className={styles.linkItem}>
                    <div className={styles.linkItemIcon}>
                        <ArrowButton type='right' size='3.75rem' />
                    </div>
                    <div className={styles.linkItemText}>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSe9vJv9e-5CAk3ltyzkIYIt7swyvehwEb0ZonmqvWU0EqPT7A/viewform?usp=sf_link" target="_blank" className={styles.helpLink}>Join the growing list</a> of signers supporting our demands be heard!
                    </div>
                </div>
                <div className={styles.linkItem}>
                    <div className={styles.linkItemIcon}>
                        <ArrowButton type='right' size='3.75rem' />
                    </div>
                    <div className={styles.linkItemText}>
                        <a href="https://www.instagram.com/armorcoalition/" target="_blank" className={styles.helpLink}>Follow @armorcoalition</a> on Instagram to stay involved with stopping COP29 in Azerbaijan!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowHelp;
