export const CCAddress = 'armorcoalition@gmail.com';

export const Subjects = [
    `Requests to restore the integrity of COP29`,
    `Don't let COP29 greenwash genocide`,
    `Requests to redeem COP29`,
    `Hold COP29 host accountable to shared global demands`
];

export const Body = `To whom it may concern, 
I am reaching out as a global citizen invested in your actions and concerned with your failure to demand accountability from Azerbaijan in the wake of hosting COP29.

Baku has shown blatant disregard for international law and has made dangerously inadequate progress toward international climate standards, increasing fossil fuel extraction while the world makes progress.

Meanwhile, the ruling Aliyev regime has been spending millions on PR and lobbying, pushing a false narrative to advance nationalist propaganda and a genocidal campaign against the neighboring indigenous Armenian population.

Communities affected by climate change and other vulnerable groups, as well as EU Parliament members who suspended Azerbaijan this year for gross human rights violations, are excluded from participating in COP29. Furthermore, at least 23 Armenian prisoners of war remain illegally detained and tortured in Azerbaijani prisons while Azerbaijan and its allies exert pressure on Armenia to accept an unjust peace deal.

COP29 must not become a platform for greenwashing fossil fuels and human rights abuses. We cannot stand by and support the systemic corruption and oppression perpetuated by Azerbaijan's ruling regime.

I urge you to take all actions within your power and release a statement in support of our demands, which have been endorsed by an international coalition of activists committed to justice:

• #FREEARMENIANHOSTAGES: Advocate for the release of the 23 Armenian hostages confirmed by the Azerbaijani government and for the 80 to 200 forcibly disappeared since 2020.

• SANCTION AZERBAIJAN: Formally call out the human rights violations, war crimes, and genocide committed by Azerbaijan against the indigenous Artsakh Armenians, and demand justice for victims through sanctions and action in the International Criminal Court.

• REINFORCE THE RIGHT TO RETURN: Support security and a safe return for forcibly displaced Artsakh Armenians to their indigenous lands.

• FREE CIVIL SOCIETY: Demand the release of over 300 political prisoners arbitrarily detained in Azerbaijani prisons, including government critics, opposition members, academics, and journalists exposing rampant state corruption.

• STOP THE HATE: Demand the protection of Armenian cultural property in occupied Artsakh and the end of all state-sanctioned Armenophobic references in education and terminology such as "Western Azerbaijan."

• MAKE COP COUNT: Ensure that future COP conferences are not hosted in countries that hold illegally detained pows, political prisoners, civilian hostages, or prisoners of conscience.


Please contact ArmorCoalition at armorcoalition@gmail.com if you have any questions or would like to support our demands.

Thank you for your attention to these urgent matters.`
